@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&family=Titan+One&display=swap');

* {
  -ms-overflow-style: none;
}

body {
  background-color: rgb(0, 0, 0);
}

/* ::-webkit-scrollbar {
  color: rgb(228, 7, 7);
  display: none;
} */

/* .MuiPaper-root .MuiPaper-elevation .MuiPaper-rounded .MuiPaper-elevation8 .MuiPopover-paper .MuiMenu-paper .MuiMenu-paper  */
.css-1nnm5g0-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  max-height: 20rem !important;
}

.css-1wubypq-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  background-color: transparent !important;
}
